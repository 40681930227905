import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'
import ReactSVG from 'react-svg'

import LazyLoad from 'react-lazyload'

import Map from '@/components/Map'
import NewestPosts from '@/components/Blog/NewestPosts'

import Layout from '@/components/Layout'
import SEO from '@/helpers/SEOHelper.js'

export const HomePageTemplate = ({ data }) => {
  return (
    <>
      {/* Hero */}
      <section className='hero'>
        <div className='fullscreen-video-wrap'>
          <video
            autoPlay={true}
            loop
            muted
            playsInline
            src={data.hero.video.mp4.publicURL}
            poster={data.hero.poster.childImageSharp.fluid.src}
          />
        </div>
        <div className='container-xl'>
          <h1
            className='text-h3 sm:text-h1 mb-6 text-white'
            style={{ textShadow: '0px 0 5px rgba(12,12,12, .9)' }}>
            {data.hero.heading}
          </h1>
        </div>
      </section>
      {/* /Hero */}

      {/* About */}
      <section className='relative mx-auto py-12 lg:py-32'>
        <div className='container-lg'>
          <div className='collective'>
            <h2 className='leading-h2multi mb-10'>{data.change.heading}</h2>
            <ReactMarkdown
              className='post-content md:max-w-full '
              source={data.change.description}
            />
          </div>
        </div>
        <div className='container-xl'>
          <div className='illustrations'>
            <ReactSVG src='img/svg/talking.svg' className='illustration' />
            <ReactSVG
              src='img/svg/picking-fruit.svg'
              className='illustration illustration--desktop'
            />
            <ReactSVG
              src='img/svg/chess.svg'
              className='illustration illustration--desktop'
            />
          </div>
        </div>
      </section>
      {/* /About */}

      <Img
        className='full-image'
        Tag='figure'
        fluid={data.hero.background2.childImageSharp.fluid}
      />

      <section className='columns columns__fixed flex-col md:flex-row'>
        <div className='columns__content min-h-mobile md:min-h-desktop'>
          <Img
            fluid={data.challenges.background.childImageSharp.fluid}
            className='columns__content__bg'
          />
          <div className='columns__content__inner'>
            <h2 className='leading-none mb-6'>{data.challenges.heading}</h2>
            <ReactMarkdown
              className='post-content md:max-w-full '
              source={data.challenges.description}
            />
          </div>
        </div>
        <div
          className='columns__video min-h-mobile sm:min-h-desktop md:h-auto'
          style={{ overflow: 'hidden' }}>
          <LazyLoad>
            <video
              autoPlay={true}
              loop
              muted
              playsInline
              src={data.challenges.video.mp4.publicURL}
              poster={data.challenges.poster.childImageSharp.fluid.src}
            />
          </LazyLoad>
        </div>
      </section>

      <section className='container-xl mx-auto py-12 lg:py-64 how'>
        <div className='flex flex-wrap justify-between items-center'>
          <h2 className='text-center md:text-left leading-none my-5'>
            {data.how.heading}
          </h2>
          <div className='text-center md:text-left how__content'>
            {data.how.content}
          </div>
        </div>
        <div className='perks'>
          {data.how.items.map((item, i) => {
            return (
              <div className='perk' key={i}>
                <ReactSVG className='perk__image' src={item.image.publicURL} />
                <div className='perk__content'>
                  <h3 className='perk__title'>{item.heading}</h3>
                  <div className='perk__description'>{item.description}</div>
                </div>
              </div>
            )
          })}
        </div>
      </section>

      <section className='relative mx-auto design  flex flex-col justify-center items-center py-12 lg:pb-64 lg:pt-32  min-h-mobile sm:min-h-desktop lg:h-auto  '>
        <div className=' container-lg flex justify-between items-center lg:pt-80'>
          <ReactSVG
            src='img/svg/walking-waving.svg'
            className='hidden lg:block illustration-height'
          />
          <div className='text-center max-w-746'>
            <h2 className='leading-none mb-6'>{data.social.heading}</h2>
            <ReactMarkdown
              className='post-content md:max-w-full '
              source={data.social.description}
            />
          </div>
          <ReactSVG
            src='img/svg/bike-riding.svg'
            className='hidden lg:block illustration-height'
          />
        </div>
        <div className='container-xl'>
          <div className='illustrations'>
            <ReactSVG
              src='img/svg/knitting.svg'
              className='block mx-auto lg:mx-0 knitting illustration-height-sm'
            />
            <ReactSVG
              src='img/svg/fruit-basket.svg'
              className='hidden lg:block illustration-height-sm'
            />
            <ReactSVG
              src='img/svg/garden.svg'
              className='hidden lg:block illustration-height-sm'
            />
            <ReactSVG
              src='img/svg/tram.svg'
              className='hidden xl:block illustration-height-sm'
            />
          </div>
        </div>
      </section>

      <section className='columns flex-col-reverse md:flex-row-reverse columns--content-dominant'>
        <div className='columns__content min-h-mobile sm:min-h-desktop md:h-auto'>
          <div className='columns__content__inner'>
            <h2 className='leading-none mb-6'>{data.advanced.heading}</h2>
            <ReactMarkdown
              className='post-content md:max-w-full '
              source={data.advanced.description}
            />
          </div>
        </div>
        <div
          className='columns__video min-h-mobile sm:min-h-desktop md:h-auto'
          style={{ overflow: 'hidden' }}>
          <LazyLoad>
            <video
              autoPlay={true}
              loop
              muted
              playsInline
              src={data.advanced.video.mp4.publicURL}
              poster={data.advanced.background.childImageSharp.fluid.src}
            />
          </LazyLoad>
        </div>
      </section>

      <section className='columns  flex-col-reverse md:flex-row columns--content-dominant'>
        <div className='columns__content min-h-mobile sm:min-h-desktop md:h-auto'>
          <div className='columns__content__inner'>
            <h2 className='leading-none mb-6'>{data.projects.heading}</h2>
            <ReactMarkdown
              className='post-content md:max-w-full '
              source={data.projects.description}
            />
          </div>
        </div>
        <div className='columns__background'>
          <Img fluid={data.projects.background.childImageSharp.fluid} />
        </div>
      </section>

      <section className='columns flex-col-reverse md:flex-row columns--image-dominant'>
        <div className='columns__content min-h-mobile sm:min-h-desktop md:h-auto'>
          <div className='columns__content__inner'>
            <h2 className='leading-none mb-6'>{data.community.heading}</h2>
            <ReactMarkdown
              className='post-content md:max-w-full '
              source={data.community.description}
            />
          </div>
        </div>
        <div className='columns__background columns__background--illustration columns__background--community'>
          <div className='columns__illustration'>
            <LazyLoad>
              <img
                src='img/svg/community.svg'
                alt='Community house illustration'
              />
            </LazyLoad>
          </div>
        </div>
      </section>

      <Img
        className='full-image'
        Tag='figure'
        fluid={data.community.background2.childImageSharp.fluid}
      />

      <section className='columns flex-col md:flex-row-reverse columns--reversed'>
        <div className='columns__content min-h-mobile sm:min-h-desktop md:h-auto'>
          <div className='columns__content__inner column__calc--right '>
            <h2 className='leading-none mb-6'>{data.affordability.heading}</h2>
            <ReactMarkdown
              className='post-content md:max-w-full '
              source={data.affordability.description}
            />
          </div>
          <div className='columns__decorator columns__decorator--flowers'>
            <ReactSVG src='img/svg/flowers.svg' />
          </div>
        </div>
        <div className='columns__background'>
          <Img fluid={data.affordability.background.childImageSharp.fluid} />
        </div>
      </section>

      <section className='flex-col columns md:flex-row columns--reversed'>
        <div className='columns__content'>
          <div className='columns__content__inner'>
            <h2 className='leading-none mb-6'>{data.chat.heading}</h2>
            <div className='relative'>
              <ReactMarkdown
                className='post-content max-w-4/6 '
                source={data.chat.content}
              />
              <div className='columns__decorator columns__decorator--laptop'>
                <ReactSVG src='img/svg/laptop.svg' />
              </div>
            </div>

            <div className='mt-10'>
              <Link to='/contact' className='btn-primary'>
                {data.chat.buttonText}
              </Link>
            </div>
          </div>
        </div>
        <div className='columns__background columns__background--map'>
          <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyALWhNZ4Pjlhqb2B831kc7m-g9D5kdaLRM&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div className='w-full h-full' />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </section>

      <section className='bg-grey-lighter mx-auto py-32'>
        <div className='container-xl'>
          <h2 className='leading-none text-center mb-10'>
            {data.inspiration.heading}
          </h2>

          <NewestPosts />

          <div className='mt-10 flex justify-center items-center'>
            <Link to='/blog' className='btn-primary'>
              {data.inspiration.buttonText}
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const seo = frontmatter.seo

  return (
    <Layout hideNav={true}>
      <Helmet>
        <meta name='description' content={SEO.description(seo.description)} />
        <meta property='og:title' content={SEO.title(seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(seo.description)}
        />
        <meta property='og:image' content={SEO.image(seo.image)} />
        <title>{SEO.title(seo.title)}</title>
      </Helmet>
      <HomePageTemplate data={frontmatter} />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default HomePage

export const pageQuery = graphql`
  query IndexPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "index" } }) {
      frontmatter {
        hero {
          heading
          background1 {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 45) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          background2 {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 45) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          poster {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 45) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          video {
            title
            videoTitle
            mp4 {
              publicURL
            }
          }
        }

        change {
          heading
          description
        }

        challenges {
          heading
          description
          poster {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 45) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          background {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 45) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          video {
            title
            videoTitle
            mp4 {
              publicURL
            }
          }
        }

        how {
          heading
          content
          items {
            image {
              publicURL
            }
            heading
            description
          }
        }

        social {
          heading
          description
          background {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 45) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        advanced {
          heading
          description
          video {
            title
            videoTitle
            mp4 {
              publicURL
            }
          }
          background {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 45) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        projects {
          heading
          description
          background {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 45) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        community {
          heading
          description
          background2 {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 45) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        affordability {
          heading
          description
          background {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 45) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        chat {
          heading
          content
          buttonText
        }

        inspiration {
          heading
          buttonText
          items {
            date
            heading
          }
        }

        seo {
          title
          description
        }
      }
    }
  }
`
